<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col dense cols="12" v-if="apms_list.length > 0">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="2" md="2" align="left"
                  >Tubing/Casing
                </v-col>
                <v-col cols="12" sm="2" md="2" align="center">Pressure</v-col>
                <v-col cols="12" sm="2" md="2" align="center"></v-col>
                <v-col cols="12" sm="6" md="6" align="left">Comment</v-col>
              </v-row>
              <v-row
                align="baseline"
                dense
                no-gutters
                v-for="item in apms_list"
                :readonly="test_readonly"
                :key="item.id"
              >
                <v-col dense cols="12" sm="2" md="2">
                  <span>
                    {{ item.label }}
                  </span>
                </v-col>

                <v-col dense cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    class="rowh0"
                    type="number"
                    outlined
                    dense
                    :clearable="!test_readonly"
                    :readonly="test_readonly"
                    v-model="item.value"
                    @input="changes"
                    @change="get_failures()"
                    :clear-value="'Not working'"
                    hide-spin-buttons
                  >
                  </v-text-field>
                </v-col>
                <v-col style="margin-left: 2px" dense cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="item.comment"
                    @input="changes"
                    :readonly="test_readonly"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import allquery from "../graphql/WellTest/TestForm/allQuery.gql";
export default {
  components: {},
  props: {
    apms: Array,
    test_readonly: Boolean,
    failures_list: Array,
    editedItem: Object,
  },

  data() {
    return {
      apms_list: [],
      all_failures_list: [],
      test_failures: [],
      barriers: [],
      well_failures: [],
    };
  },

  mounted() {
    this.getdata();
    this.apms_list = Object.assign([], this.apms);
    this.well_failures = Object.assign([], this.failures_list);
  },
  computed: {},
  watch: {},
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    changes() {
      this.$emit("changes", this.apms_list);
    },
    async getdata() {
      let r = await this.$requette(allquery);
      if (r.ok) {
        this.all_failures_list = r.data.failures_list;
        this.barriers = r.data.wmf_list;
      }
    },
    valuechanged_pressure(item) {
      if (item.value) {
        if (item.value >= item.actual_maasp) this.set_falure(item, 186, 1);
        // else if (item.value < item.maasp) this.set_falure(item, 187, 1);
      }
    },
    set_falure(item, failure, gid) {
      let fail = {
        id: null,
        state: 1,
        cmp_code: item.cmp_code,
        cmp_id: item.cmp_id,
        failure_id: failure,
        welltest_id: this.editedItem.id,
        g_id: gid,
        testtype: this.editedItem.testtype_id,
      };

      this.test_failures.push(
        this.$calc_single(this.barriers, this.all_failures_list, fail)
      );
    },
    get_failures() {
      this.test_failures = [];
      // get actual failures
      this.apms_list.forEach((item) => {
        this.valuechanged_pressure(item);
      });
      //calculate multiples
      let multiples = this.$get_multiples(
        this.well_failures,
        this.test_failures.filter((x) => x.cmp_code != "0" && x.failure_id > 0),
        this.barriers,
        this.all_failures_list,
        this.editedItem.id
      );

      // add multiples
      if (multiples)
        if (multiples.length > 0) {
          var max = multiples.reduce(function (prev, current) {
            if (+current.failure_taux < +prev.failure_taux) {
              return current;
            } else {
              return prev;
            }
          });
          this.test_failures.push(max);
        }
      // if (multiples) multiples.forEach((m) => this.test_failures.push(m));
      this.$emit("changes", this.apms_list);
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
